
  import { defineComponent, ref, watchEffect } from 'vue'
  import MyUploadBtn from '../../../components/MyUpload/index.vue'
  import { BookFilled, DeleteFilled } from '@ant-design/icons-vue'
  import { parseInt } from 'lodash'
  export default defineComponent({
    props: {
      datajson: String,
    },
    components: { MyUploadBtn, BookFilled, DeleteFilled },
    setup(props, ctx) {
      const items: any = ref([])
      const calculation = ref(0)
      const supplier_total_price = ref('')
      const supplier_actual_total_price = ref('-')
      watchEffect(() => {
        const data = props.datajson as any
        if (data) {
          items.value = JSON.parse(data)
          Calculation()
        }
      })
      const Calculation = () => {
        let number = 0
        items.value.forEach((res: any) => {
          res.content.forEach((json: any) => {
            number += parseFloat(json.total)
          })
        })
        calculation.value = number
      }
      const uploadInputFun = (row: any) => {
        ctx.emit('onchange', JSON.stringify(items.value))
        console.log(items.value, 'kskks')
      }
      const update_prices_fun = (e: any) => {
        let number = 0
        items.value.forEach((res: any) => {
          res.content.forEach((json: any) => {
            number +=
              parseInt(json.page_number) * parseFloat(json.supplier_price)
          })
        })
        supplier_total_price.value = number.toFixed(2)
        console.log(e, '22ss')
      }
      const onCalculation = () => {
        let fee_num = 0
        items.value.forEach((res: any) => {
          res.content.forEach((json: any) => {
            if (json.supplier_number && json.supplier_price) {
              fee_num +=
                parseFloat(json.supplier_price) * parseInt(json.supplier_number)
            }
            console.log(json, '00')
          })
        })
        supplier_actual_total_price.value = fee_num ? fee_num.toFixed(2) : '-'
      }
      return {
        items,
        calculation,
        uploadInputFun,
        update_prices_fun,
        supplier_total_price,
        onCalculation,
        supplier_actual_total_price,
      }
    },
  })
